@import 'theme';
@import 'ui/cards/colors';

.card-help-container {
  display: flex;
  padding: 1em;
  box-sizing: border-box;
  background-color: $bgLight;
  border-radius: 0.3em;
  margin-bottom: 2em;
  box-shadow: $dropShadow;
}

.card-help-container p {
  text-align: left;
  margin-bottom: 1em;
}

.help-container strong {
  font-weight: 500;
  color: $accent;
}

.card-shadow-container {
  margin-top: 0.5em;
  font-size: 0.8em;
  border-radius: 1em;
  overflow: visible;
  box-shadow: 0.5em 0.5em 1em -0.3em rgba(0,0,0,0.75);
  margin-bottom: auto;
}

.card-info-container {
  margin-left: 1em;
  flex-grow: 1;
}

.card-info-header {
  font-size: $fontSizeCaption;
  font-weight: 600;
  //padding: 0.3em 1em;
  margin: 0 0 0.2em;
  width: 100%;
  color: $accent;
  border-radius: 0.3em;
  box-sizing: border-box;
  text-transform: lowercase;
}

.card-event-icon {
  width: 3em;
  height: 3em;
  fill: $accent;
}