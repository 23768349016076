@import 'theme';

.merry-christmas-animation {
  position: relative;
  @include fillCenter;

  & .snow {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & .christmas-tree {
    position: relative;
    @include center;

    & .needles {
      top: 0;
      $size: 5em;
      width: 0;
      height: 0;
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-bottom: $size solid $accent;
      position: absolute;

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          top: -7em + $i * 2;
          z-index: -$i;
        }
      }
    }

    & .log {
      position: absolute;
      background-color: $accent;
      width: 2em;
      height: 1.8em;
      top: 4.2em;
      z-index: -4;
    }

    & .floor {
      position: absolute;
      top: 6.2em;
      height: 0.1em;
      width: 8em;
      background-color: $accent;
    }

    & .chain {
      position: absolute;

      &:nth-of-type(1) {
        top: -1.5em;
        left: 0;
      }

      &:nth-of-type(2) {
        top: 0;
        left: -1.5em;
      }

      &:nth-of-type(3) {
        top: 2em;
        left: -1.5em;
      }

      &:nth-of-type(4) {
        top: 4em;
        left: -1.5em;
      }

      @for $i from 1 through 4 {
        @for $j from 1 through 8 {
          &:nth-of-type(#{$i}) > .balls:nth-child(#{$j}) {
            animation-delay: #{-$i * 1.2 + $j * 0.2}s;
          }
        }
      }

      & .balls {
        position: absolute;
        width: 0.7em;
        height: 0.7em;
        border-radius: 50%;
        background-color: white;
        box-shadow: 0 0 0.5em white;
        z-index: 1;
        animation: 2s ball-light infinite ease-in-out;

        @for $i from 1 through 8 {
          &:nth-child(#{$i}) {
            top: #{($i - 5) * 0.4}em;
            left: #{$i - 3}em;
          }
        }
      }
    }
  }
}

@keyframes ball-light {
  0%, 20% {
    background-color: white;
    box-shadow: 0 0 0.5em white;
  }
  50% {
    background-color: $accent;
    box-shadow: 0 0 0.5em $accent;
  }
  80%, 100% {
    background-color: white;
    box-shadow: 0 0 0.5em white;
  }
}