@import 'theme';

.game-table, .game-overlay {
  position: absolute;
  @include fullscreen;
  @include center;
  top: 0;
  left: 0;
  overflow: hidden;
}

.game-table {
  & > :not(.timer-container):not(.game-chat-container){
    transition: filter 0.5s;
  }

  &.overlayed > :not(.timer-container):not(.game-chat-container) {
    filter: blur(0.3em);
    pointer-events: none !important;
  }
}

.game-overlay {
  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}

.game-stack-spacer {
  width: 20vw;
}

.game-player {
  //may be needed for more space...
  //font-size: 0.8em;
  position: absolute;
  bottom: -4em;
}

.game-opponents {
  position: absolute;
  top: 0;
  left: 0;
  @include fullscreen;
  transition: filter 0.5s;

  &.overlayed {
    filter: blur(0.3em);
    pointer-events: none !important;
  }
}

.game-opponent-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;

  &.top, &.bottom {
    left: 10vw;
    width: 80vw;
    height: 10em;
  }

  &.left, &.right {
    top: 10vh;
    height: 80vh;
    width: 10em;
  }

  &.top {
    top: -2.5em;
    flex-direction: row-reverse;
  }

  &.left {
    left: -3em;
    flex-direction: column;
  }

  &.right {
    right: -3em;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}


.end-turn-container {
  width: 15em;
  @include center;
  flex-shrink: 0;
}

.timer-container {
  position: absolute;
  bottom: 1em;
  left: 20vh;
}

.player-info-container {
  position: absolute;
  bottom: -4.2em;
  left: -3em;
  @include center;
  filter: drop-shadow($dropShadow);
}

.game-chat-container {
  pointer-events: all;
  position: absolute;
  bottom: 0;
  right: 10vw;
}