@import 'theme';
@import 'ui/cards/colors';

.time-bomb-animation {
  font-size: 1.5em;

  & .bomb-container {
    @include center;
    transform: rotate(90deg);
  }

  & .bomb-timer {
    position: absolute;
    width: 4em;
    height: 2.5em;
    background-color: $black;
    transform: rotate(-90deg);
    z-index: 3;
    border-radius: 0.2em;

    & .display {
      position: absolute;
      top: 0.25em;
      left: 0.25em;
      width: 3.5em;
      height: 2em;
      background-color: white;
      @include center;
      font-family: monospace;
      font-weight: 500;
      color: $black;
      animation: tb-display-blink 1s 2.5s 3;
    }
  }

  & .dynamite {
    position: relative;
    width: 1.5em;
    height: 8em;
    background-color: $accent;
    @include center;
    margin: 0.1em;
    z-index: 1;

    &::before {
      position: absolute;
      top: -0.25em;
      display: block;
      content: '';
      height: 0.5em;
      width: 1.5em;
      background-color: $accent;
      border-radius: 50%;
      border: 0.1em solid $black;
    }

    &::after {
      position: absolute;
      bottom: -0.25em;
      display: block;
      content: '';
      height: 0.5em;
      width: 1.5em;
      background-color: $accent;
      border-radius: 50%;
    }
  }

  & .wires {
    $size: 12em;
    position: absolute;
    left: -3.8em;
    top: -2em;
    transform: rotate(-90deg);
    z-index: 2;
    width: $size;
    height: $size;
  }
}

@keyframes tb-display-blink {
  0%, 49% {
    color: $black;
  }
  50%, 99% {
    color: transparent;
  }
  100% {
    color: $black;
  }
}