@import 'theme';
@import 'ui/cards/colors';

.charity-container {
  position: relative;
  @include center;
  height: 10.2em;
}

.charity-box {
  z-index: 3;
  align-self: flex-end;
  opacity: 0;
  animation-name: charity-box-appear, charity-box-disappear;
  animation-duration: 1s, 1s;
  animation-delay: 0s, 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  & .bot {
    z-index: 3;
    position: relative;
    width: 12em;
    height: 7em;
    background-color: $accent;
    filter: brightness(0.8);
  }

  & .charity-logo {
    width: 3em;
    height: 3em;
    z-index: 3;
    position: absolute;
    fill: $bgDark;
    transform: translateX(4.55em) translateY(-5em);
  }

  & .top-side {
    z-index: 3;
    position: absolute;
    transform: translateX(-0.5em) translateY(-8.4em);
    width: 13em;
    height: 1.4em;
    background-color: $accent;
    filter: brightness(0.9);
  }

  & .top-side-shadow1 {
    z-index: 3;
    position: absolute;
    transform: translateY(-8.4em);
    width: 13em;
    height: 0.1em;
    filter: blur(0.2em);
    background-color: $black;
  }

  & .top-side-shadow2 {
    z-index: 3;
    position: absolute;
    transform: translateY(-7em);
    width: 12em;
    height: 0.18em;
    background-color: $black;
    opacity: 0.5;
  }

  & .top-front {
    z-index: 5;
    position: absolute;
    transform: translateX(-0.5em) translateY(-9.475em);
    border-bottom: 1.1em solid $accent;
    border-left: 1.1em solid transparent;
    border-right: 1.1em solid transparent;
    width: 10.8em;
    filter: brightness(1);
  }

  & .opening{
    z-index: 6;
    position: absolute;
    transform: translateX(4.5em) translateY(-9.6em);
    width: 3em;
    height: 0.1em;
    background-color: $black;
    opacity: 1;
  }
}

.top-back {
  z-index: 1;
  position: absolute;
  transform: translateX(0em) translateY(-4.88em);
  border-bottom: 1.1em solid $accent;
  border-left: 1.1em solid transparent;
  border-right: 1.1em solid transparent;
  width: 8.7em;
  filter: brightness(1);
  animation-name: appear, disappear;
  animation-duration: 1s, 1s;
  animation-delay: 0s, 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.charity-coin {
  z-index: 2;
  position: absolute;
  transform: translateX(-5.5em);
  opacity: 0;
  animation-name: charity-coin-animation;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  $animationDelay: 0.7s;

  @for $i from 1 through 3 {
    &:nth-of-type(#{$i}) {
      animation-delay: $animationDelay * $i;
    }
  }

  & .outer-coin {
    z-index: 3;
    position: absolute;
    transform: translateX(4.5em) translateY(-9.6em);
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background-color: $bgLighter;
    filter: brightness(3);
    opacity: 1;
  }

  & .inner-coin {
    z-index: 3;
    position: absolute;
    transform: translateX(4.8em) translateY(-9.3em);
    width: 1.4em;
    height: 1.4em;
    border-radius: 50%;
    background-color: $bgDark;
    opacity: 0.2;
  }
}

.big-charity-logo {
  width: 3em;
  height: 3em;
  z-index: 6;
  position: absolute;
  fill: $accent;
  opacity: 0;
  transform: translateX(0.05em) translateY(1.6em);
  animation-name: big-charity-logo-expand;
  animation-duration: 2s;
  animation-delay: 3.1s;
}

@keyframes charity-box-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes charity-box-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes charity-coin-animation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
    transform: translateX(-5.5em);
  }
  20% {
    opacity: 1;
    transform: translateX(-5.5em) translateY(-0.5em);
  }
  90% {
    opacity: 1;
    transform: translateX(-5.5em) translateY(10em);
  }
  100% {
    opacity: 0;
    transform: translateX(-5.5em) translateY(10em);
  }
}

@keyframes big-charity-logo-expand {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0em);
    opacity: 1;
    filter: brightness(1);
    width: 15em;
    height: 15em;
  }
  18% {
    transform: translateY(0em);
    opacity: 1;
    filter: brightness(1);
    width: 10em;
    height: 10em;
  }
  24% {
    transform: translateY(0em);
    opacity: 1;
    filter: brightness(1);
    width: 13em;
    height: 13em;
  }
  28% {
    transform: translateY(0em);
    opacity: 1;
    filter: brightness(1);
    width: 12em;
    height: 12em;
  }
  30% {
    transform: translateY(0em);
    opacity: 1;
    filter: brightness(1);
    width: 12.5em;
    height: 12.5em;
  }
  100% {
    transform: translateY(0em);
    opacity: 1;
    filter: brightness(1);
    width: 12.5em;
    height: 12.5em;
  }
}