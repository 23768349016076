@import 'theme';

.lobby-container {
  max-height: 41.2em;
  @include center;
}

.lobby-column {
  &.main {
    flex-grow: 2;
  }
  &.chat {
    align-self: stretch;
    flex-grow: 1;
    margin-left: 5%;
  }
}

.lobby-window-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.lobby-window-column {
  width: 45%;
  display: block;
}