@import 'theme';

.tab-container {
  width: 100%;
  border-radius: 0.3em;
}

.tab-header-container {
  display: flex;
  justify-content: space-around;
  justify-items: center;
  padding: 1em;
}

.tab-header {
  fill: $accent;
  color: $accent;
  background-color: $bgLight;
  font-size: $fontSizeSmallHeader;
  font-weight: 500;
  text-transform: lowercase;
  line-height: 1em;
  padding: 0.5em 1em;
  margin: 0 0.5em;
  border-radius: 1em;
  flex-grow: 1;
  @include center;
  @include clickable;

  &.selected {
    fill: $bgMedium;
    color: $bgMedium;
    background-color: $accent;
    flex-grow: 2;
  }
}

.tab-content {
  max-width: 100%;
  //max-height: calc(100% - 4.8em);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  @include scrollbar;
}