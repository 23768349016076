$red: #d0250d;
$yellow: #ffcd2a;
$green: #78b917;
$blue: #0861a9;
$black: #282c34;
$white: invert($black);

.red {
  background-color: $red;
}

.green {
  background-color: $green;
}

.blue {
  background-color: $blue;
}

.yellow {
  background-color: $yellow;
}

.black {
  background-color: $black;
}

.white {
  color: $black;
  background-color: $white;
}

.accent {
  background-color: $accent;
}

.special {

  &.matte {
    background-color: $black;
  }

  &.opaque {
    background-color: rgba($black, 0.88);
  }

  &.colorful {
    background-color: transparent;
  }
}

.multicolor-old {
  background: linear-gradient($red, $yellow, $green, $blue, $red);
  background-size: 1800% 1800%;
  animation: rainbow-3 10s infinite;
}

.multicolor {
  &::before {
    content: '';
    position: absolute;
    width: 175%;
    height: 175%;
    background: conic-gradient($red, $yellow, $green, $blue, $red);
    animation: rainbow-rotate 4s linear infinite;
  }
}

.multicolor-3 {
  &::before {
    content: '';
    position: absolute;
    top: -120%;
    width: 800%;
    height: 800%;
    background: conic-gradient($red, $yellow, $green, $blue, $red);
    animation: rainbow-rotate 4s linear infinite;
  }
}

@keyframes rainbow {
  0%, 100% {
    background-color: $red;
  }
  25% {
    background-color: $yellow;
  }
  50% {
    background-color: $green;
  }
  75% {
    background-color: $blue;
  }
}

@keyframes rainbow-3 {
  0%, 100% {
    background-position: 50% 0%;
  }
  25% {
    background-position: 25% 50%;
  }
  50% {
    background-position: 50% 100%;
  }
  25% {
    background-position: 75% 50%;
  }
}

@keyframes rainbow-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}