@import 'theme';
@import 'ui/cards/colors';

.tornado-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 10.2em;

  & .whirl {
    width: 15em;
    height: 0;
    opacity: 0;
    border-radius: 1em;
    background-color: $accent;
    animation-name: tornado-whirl, tornado-whirl-fadein;
    animation-duration: 2.5s, 0.5s;
    animation-iteration-count: infinite, 1;
    animation-timing-function: ease-in-out, ease;
    animation-delay: -3s, 0s;
    animation-fill-mode: forwards;
    $animationDelay: 0.45s;
    $fadeDelay: 0.25s;
    overflow: hidden;

    &::before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(
                      90deg,
                      transparent 25%,
                      rgba(white, 0.15),
                      transparent 75%
      );
      background-size: 400% 100%;
      animation: tornado-whirl-background 2.5s linear infinite;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: -($animationDelay * $i), ($fadeDelay * 5) - ($fadeDelay * $i);

        &::before {
          animation-delay: -($animationDelay * $i);
        }
      }
    }

    &:nth-child(2) {
      width: 12em;
    }

    &:nth-child(3) {
      width: 9em;
    }

    &:nth-child(4) {
      width: 6em;
    }

    &:nth-child(5) {
      width: 4em;
    }
  }

  & .ground {
    height: 0.1em;
    width: 13em;
    background-color: $accent;
  }

  & .rubble-container {
    display: block;
    position: absolute;
    animation-duration: 3s, 1s;
    animation-timing-function: ease-in-out, ease;
    animation-iteration-count: infinite, 1;
    animation-fill-mode: forwards;
    opacity: 0;

    &.top {
      top: 0;
      animation-name: tornado-rubble-fly-top, tornado-rubble-fadein;
      animation-delay: 1.5s, 2s;
    }

    &.mid {
      top: 3.5em;
      animation-delay: 0.75s, 1.25s;
      animation-name: tornado-rubble-fly-mid, tornado-rubble-fadein;
    }

    &.bot {
      top: 7em;
      animation-delay: -0.5s, 0.5s;
      animation-name: tornado-rubble-fly-bot, tornado-rubble-fadein;
    }
  }

  & .rubble {
    display: block;
    width: 0.5em;
    height: 0.7em;
    background-color: white;
    animation: tornado-rubble-rotate 3s linear infinite;
  }
}

@keyframes tornado-whirl-fadein {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 2em;
    opacity: 1;
  }
}

$tornadoShift: 0.5em;
@keyframes tornado-whirl {
  0% {
    transform: translateX(-$tornadoShift);
  }
  50% {
    transform: translateX($tornadoShift);
  }
  100% {
    transform: translateX(-$tornadoShift);
  }
}

@keyframes tornado-whirl-background {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes tornado-rubble-rotate {
  0% {
    transform: rotate(0) rotateX(0);
  }
  50% {
    transform: rotate(1turn) rotateX(1turn) rotateY(0.5turn);
  }
  100% {
    transform: rotate(2turn);
  }
}

@keyframes tornado-rubble-fly-top {
  0% {
    transform: translateX(-10em);
    z-index: 1;
  }
  50% {
    transform: translateX(10em);
    z-index: 1;
  }
  70% {
    z-index: -1;
  }
  100% {
    transform: translateX(-10em);
    z-index: -1;
  }
}

@keyframes tornado-rubble-fly-mid {
  0% {
    transform: translateX(-8em);
    z-index: 1;
  }
  50% {
    transform: translateX(8em);
    z-index: 1;
  }
  60% {
    z-index: -1;
  }
  100% {
    transform: translateX(-8em);
    z-index: -1;
  }
}

@keyframes tornado-rubble-fly-bot {
  0% {
    transform: translateX(-5em);
    z-index: 1;
  }
  50% {
    transform: translateX(5em);
    z-index: 1;
  }
  60% {
    z-index: -1;
  }
  100% {
    transform: translateX(-5em);
    z-index: -1;
  }
}

@keyframes tornado-rubble-fadein {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
}