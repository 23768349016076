@import 'theme';

.turn-timer-container {
  display: flex;
  flex-direction: column-reverse;
}

.turn-timer-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  text-shadow: $dropShadow;
  margin-top: 0.5em;

  &.bomb {
    animation: timer-bomb-transition 0.5s;
  }
}

$timerSize: 2em;

.turn-timer {
  flex-shrink: 0;
  height: $timerSize;
  width: $timerSize;
  display: block;
  position: relative;
  border-radius: 50%;
  filter: drop-shadow($dropShadow);
}

.turn-timer-spinner {
  flex-shrink: 0;
  fill: $accent;
}

.turn-timer-seconds {
  flex-shrink: 0;
  color: $accent;
  margin: 0 0 0 0.5em;
  font-size: 1.5em;
  line-height: 1em;
  height: 1em;
  font-weight: 500;

  &.bomb {
    margin-top: 0.8em;
  }
}

.turn-timer-bomb {
  width: 3em;
  height: 3em;
  fill: $accent;
  filter: drop-shadow($dropShadow);
  margin-left: -0.25em;
  margin-right: -0.6em;
  margin-bottom: 0;
}

@keyframes timer-bomb-transition {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}