@import 'theme';

.settings-card-display {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 0.8em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.settings-button-container {
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: space-around;
}