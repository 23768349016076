@import 'theme';
@import 'ui/cards/colors';

.friday-the-13th-animation {
  position: relative;
  @include center;
  flex-direction: column;


  & .house {
    position: relative;
    width: 13em;
    height: 7.5em;
    margin-top: 0.3em;
    background: $accent;
    z-index: 5;
  }

  & .roof {
    border-bottom: 3em solid $accent;
    border-left: 2.5em solid transparent;
    border-right: 2.5em solid transparent;
    height: 0;
    width: 11em;
  }

  & .chimney {
    width: 1.5em;
    height: 1.6em;
    background: $accent;
    position: absolute;
    right: 4.5em;
    top: -1.8em;

  }

  & .window {
    width: 3.2em;
    height: 3.2em;
    background: $bgDark;
    position: absolute;
    animation: tv-flickering 1.8s infinite;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom,
              transparent 45%, $accent 45%,
              $accent 55%, transparent 55%),
      linear-gradient(to right, transparent 45%, $accent 45%,
                      $accent 55%, transparent 55%);
    }

    &:nth-child(1) {
      position: absolute;
      left: 2.2em;
      top: 2.2em;
    }

    &:nth-child(2) {
      position: absolute;
      right: 2.2em;
      top: 2.2em;
    }


  }

  & .killer {
    position: absolute;
    left: 2em;
    bottom: 3em;
    z-index: 1;


    & .killer-mask {
      fill: $accent;
      height: 2em;
      width: auto;
      animation: freddy-peeking 2.5s forwards;
      animation-delay: 1s;
    }


    & .cleaver {
      position: absolute;
      animation: move-cleaver 2.5s forwards;
      animation-delay: 1.5s;
      left: 0.2em;
      z-index: 1;

      & .cleaver-stick {
        position: absolute;
        left: 1.2em;
        width: 1em;
        height: 0.25em;
        background: $accent;
      }

      & .cleaver-blade {
        position: absolute;
        width: 1.3em;
        height: 0.9em;
        background: $accent;
        border-radius: 0 0 0 0.2em;
      }

      & .cleaver-hole {
        position: absolute;
        width: 0.25em;
        height: 0.25em;
        top: 0.1em;
        left: 0.1em;
        border-radius: 50%;
        background: $black;
      }
    }




  }


}

@keyframes freddy-peeking {
  100% {
    transform: translateX(-1.75em) rotate(-27deg);
  }
}

@keyframes move-cleaver {
  100% {
    transform: rotate(27deg) translate(-1.9em, 0.7em);
  }
}

@keyframes tv-flickering {
  10% {
    background: lighten($black, 30%);
  }
  15% {
    background: $black;
  }
  25% {
    background: lighten($black, 40%);
  }
  40% {
    background: $black;
  }
  55% {
    background: lighten($black, 30%);
  }
  65% {
    background: $black;
  }
  80% {
    background: lighten($black, 40%);
  }
  100% {
    background: $black;
  }

}

