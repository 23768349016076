@import 'theme';

.ctp-container {
  width: 100%;
  height: 90%;
  min-height: 35em;
  position: relative;
  @include center;
}

.ctp-cards {
  max-width: 90%;
  position: absolute;
  bottom: 0;
  @include center;
  height: 5em;
}

.ctp-players {
  position: absolute;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 5em;
  width: 100%;
  transition: 0.3s;
}

.ctp-player-item {
  position: relative;
  font-size: 0.6em;
  margin: 3em 5em 3em;
  pointer-events: all;
  transition: 0.3s;
  min-height: 100%;

  &.hovered {
    filter: brightness(110%);
  }
}

.ctp-player-item .opponent-avatar-container:hover {
  cursor: pointer;
}

.react-draggable-transparent-selection {
  .ctp-player-mousecapture:hover {
    cursor: grab;
  }

  .ctp-player-item .opponent-avatar-container:hover {
    cursor: grab;
  }
}

.ctp-card-shrinker {
  min-width: 0;
  flex-basis: auto;
  flex-shrink: 1;
  @include center;
}

.ctp-card-item {
  cursor: grab;
  font-size: 0.5em;
  margin: 0.5em;
  border-radius: 0.8em;
  box-sizing: border-box;

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    cursor: grabbing;
  }
}

.ctp-target-test {
  width: 5em;
  height: 5em;
  position: absolute;
  top: 3em;
  background-color: $accent;
}