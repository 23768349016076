@import 'theme';
@import 'ui/cards/colors';

.picker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  z-index: 1;
  padding: 0.5em;

  &.scrolling {
    max-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbar;
  }
}

.picker-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 0.5em 0.5em 0;
  padding: 0.5em;
  @include clickable;
  pointer-events: none;
  max-width: 5em;

  &.active {
    font-weight: 500;
    color: $accent;
  }

  & .opponent-transition-container {
    @include center;
    width: 100%;
  }

  & .opponent-stats-container {
    font-size: 0.8em;
    position: absolute;
    bottom: -5em;
  }
}

.picker-name {
  margin: 0.5em 0 0;
  pointer-events: all;
  text-align: center;
  word-break: break-all;
}

.picker-cn-item {
  font-size: 3.5em;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  pointer-events: all;
  transition: 0.3s;
  box-sizing: border-box;
  border: 0.1em solid $bgLighter;
  @include center;

  &.number {
    background-color: $bgLighter;
  }

  &.active {
    border: 0.04em solid $accent;
    box-shadow: 0 0 0.2em $accent;
    color: $accent;
    font-weight: 500;
  }
}

.picker-cn-number {
  font-size: 0.5em;
}

.picker-card-item {
  font-size: 0.5em;
  flex-shrink: 0;
  margin: 0.5em;
  @include clickable;
  border-radius: 0.8em;
  box-sizing: border-box;
  border: 0.28em solid transparent;

  &.active {
    border: 0.28em solid $accent;
    box-shadow: 0 0 1.4em $accent;
  }
}

.picker-separator-container {
  margin-top: 0.5em;
  width: 100%;
  @include center;
}

.picker-separator {
  color: $accent;
  font-weight: 500;
  text-align: center;
  flex-grow: 0;

  &.line {
    border-bottom: 0.1em solid $accent;
    flex-grow: 1;
    margin: 1em;
  }
}