@import 'theme';
@import 'ui/cards/colors';

.gambling-man-animation {
  @include center;
  position: relative;

  & .pull-stick {
    position: absolute;
    background: $accent;
    height: 7em;
    width: 0.5em;
    right: -14em;
    bottom: -4em;
    animation: pull 1s forwards;
    animation-delay: 1s;
  }
  & .pull-handle {
    position: absolute;
    background: $accent;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    right: -14.7em;
    top: -4em;
    animation: pull-handle 1s forwards;
    animation-delay: 1s;
  }

  & .slot-machine {
    @include center;
    flex-direction: row;
    position: absolute;
    border: 0.5em solid $accent;
    width: 20em;
    height: 8em;
    border-radius: 1em;
    overflow: hidden;

    & .slot-container {
      @include center;
      position: relative;
      flex-direction: column;
      flex-wrap: wrap;
      width: 30%;
      height: auto;
      top: 22em;
      animation: slot1 2s forwards;

      &:nth-child(1) {
        animation-delay: 1.5s;
      }
      &:nth-child(2) {
        animation-delay: 2s;
      }
      &:nth-child(3) {
        animation-delay: 1.7s;
      }

      & .slot {
        fill: $accent;
        margin: 0.5em 0;
        height: 4.4em;
        width: 75%;
      }
    }
  }
}

@keyframes slot1 {
  100% {
    transform: translateY(-64%);
  }
}

@keyframes pull {
  50% {
    height: 0;
  }
  100% {
    height: 7em;
  }
}

@keyframes pull-handle {
  50% {
    transform: translateY(6em) scale(1.4);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}