@import 'theme';

.themepicker-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.themepicker-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 0.5em 0.5em 0;
  padding: 0.5em;
  @include clickable;
  width: 6em;

  &.active {
    font-weight: 500;
    color: $accent;

    & > .themepicker-theme {
      border: 0.1em solid $accent;
      box-shadow: 0 0 1em $accent;
      background-color: $accent;
    }
  }
}

.themepicker-theme {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 5em;
  width: 5em;
  overflow: hidden;
  border-radius: 0.4em;
  box-shadow: $dropShadow;
}

.themepicker-name {
  margin: 1em 0 0;
  pointer-events: all;
  text-align: center;
  text-transform: lowercase;
}

.themepicker-color {
  &:nth-child(1) {
    width: 2em;
    height: 100%;
  }
  &:nth-child(3) {
    @include center;
    font-weight: 300;
  }

  width: 3em;
  height: 1.25em;
}

.themepicker-color-text {
  position: absolute;
}