@import 'theme';

.error-center {
  @include fillCenter;
}

.error-box {
  color: $bgMedium;
  background-color: $accent;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 0.5em;
  box-shadow: $dropShadow;
}

.error-icon {
  margin: 0;
  flex-shrink: 0;
  font-size: 5em;
  line-height: 1em;
  font-weight: 500;
}

.error-content-container {
  margin-left: 1em;
}

.error-content {
  box-sizing: border-box;
  font-weight: 500;
  max-height: calc(100% - 2.3em);
  max-width: calc(100% - 3.3em);
  overflow: auto;
  @include scrollbar;

  &::-webkit-scrollbar-thumb {
    background-color: $bgMedium;
  }
}

.error-title {
  margin: 0 0 0.2em;
  font-size: $fontSizeCaption;
  text-transform: lowercase;
}

.error-content p {
  margin: 0;
}

.error-content code {
  float: left;
  box-sizing: border-box;
  background-color: $bgMedium;
  color: $accent;
  border-radius: 0.3em;
  padding: 0.3em;
}