@import 'theme';

.player-list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.player-item {
  // see transition in PlayerList.js!
  width: 100%;
  height: 2.8em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $bgLight;
  box-shadow: $dropShadow;
  margin-bottom: 0.6em;
  padding: 0.6em 0.9em 0.6em 0.6em;
  border-radius: 0.3em;
  box-sizing: border-box;
  overflow: hidden;

  &:first-child {
    margin-top: 0.6em;
  }
}

.player-item-text {
  margin: 0 0 0 0.5em;
  width: 100%;
  text-transform: none;
  font-weight: 500;
}

.player-item-decor {
  fill: $accent;
  width: 1.7em;
  height: 1.7em;

  &.clickable {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.2em;
    @include clickable;
  }
}