@import 'theme';

.window-container {
  color: $textColor;
  background-color: $bgMedium;
  border-radius: 0.8em;
  overflow: hidden;
  box-shadow: $dropShadow;
  padding: 0;
}

.window-header {
  background-color: $accent;
  font-size: $fontSizeHeader;
  color: $bgMedium;
  height: 1.8em;
  margin: 0;
}

.window-title {
  margin: 0 0.7em;
  font-size: $fontSizeHeader;
  height: 100%;
  width: 100%;
  line-height: 2em;
  font-weight: 600;
  text-transform: lowercase;
  text-align: left;
}

.window-body {
  margin: 0;
  padding: 2em;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 2.66rem * 1.8);
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
}

.window-content {
  min-height: 5em;
  margin: 0;
  padding: 0;
}