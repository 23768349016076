@import 'theme';

.hand-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

// this actually is in the flexbox
// and acts as the spring transition div
.hand-card-container {
  pointer-events: none;
  transition: 0s;

  .transition > & > .hand-card {
    transition: all 0.3s, font-size 0s;
  }

  & > .hand-card:hover {
    margin-right: 5em;
  }

  &:last-child > .hand-card:hover {
    margin-right: 0 !important;
  }
}

// this holds the card and get the hover events
// from the -container
.hand-card {
  position: relative;
  transform-origin: center;
  @include center;
  pointer-events: all;
}

.card-border {
  border-radius: 0.8em;
  transition: 0.3s;

  &.available {
    cursor: pointer;
    border: 0.2em solid $availableCardColor;
    box-shadow: 0 0 0.5em 0 $availableCardColor;
  }

  &.unavailable {
    //filter: grayscale(0.5);
    cursor: not-allowed;
  }
}