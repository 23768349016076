@import 'theme';
@import 'ui/cards/colors';

.event-overlay-container {
  height: 100%;
  width: 120vw;
  background-color: $black;
  color: $accent;
  transform: rotate(-10deg);
  overflow: hidden;
  @include center;

  & > * {
    transform: rotate(10deg);
  }
}

.event-overlay-content {
  position: relative;
  width: 100%;
  height: 100vh;
  @include center;
  animation-name: overlay-fade;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-play-state: paused;

  &.out {
    animation-play-state: running;
    animation-direction: normal;
    animation-delay: 5s;
  }
  &.in {
    animation-play-state: running;
    animation-direction: reverse;
  }

  & > .overlay-message {
    max-width: 30em;
  }
}

.overlay-message {
  color: $accent;
  max-width: 75vw;
  @include center;
  flex-direction: column;

  & h1 {
    font-size: $fontSizeHeader;
    font-weight: 500;
    margin: 0;
  }

  & p {
    color: $textColor;
    margin: 0;
    text-align: center;
  }
}

.overlay-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.overlay-message-icon {
  height: $fontSizeHeader;
  width: $fontSizeHeader;
  fill: $accent;
  margin-right: 1em;
}

@keyframes overlay-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}