@import 'theme';
@import 'ui/cards/colors';

.card-container {
  font-size: 2.8em;
  width: 3.1em;
  height: 4.4em;
  border-radius: 0.2em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-weight: 300;
  color: white;
  fill: white;
  flex-shrink: 0;
  user-select: none;

  &.red > .ribbon {
    color: $red;
  }

  &.yellow > .ribbon {
    color: $yellow;
  }

  &.green > .ribbon {
    color: $green;
  }

  &.blue > .ribbon {
    color: $blue;
  }

  &.multicolor > .ribbon {
    color: $black;
  }

  &.accent > .ribbon {
    color: $accent;
  }

  &.white > .ribbon {
    color: $white;
    background-color: $black;

    & > .ribbon-inner {
      font-weight: 300;

      &:before {
        border-bottom: .5em solid darken($black, 30);
      }

      &:after {
        border-top: .5em solid darken($black, 30);
      }
    }
  }
}

.card-inner {
  width: calc(100% - 0.1em);
  height: calc(100% - 0.1em);
  border-radius: inherit;
  position: absolute;
}

.card-glare {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  position: absolute;
}

.card-shadow {
  box-shadow: $dropShadow;
  box-sizing: border-box;
}

$borderMargin: 0.5em;

.card-icon {
  text-align: center;
  display: block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  margin: 0;
  z-index: 0;
  fill: currentColor;

  &.center {
    font-size: 1.2em;
  }

  &.border {
    font-size: 0.4em;
    position: absolute;

    &.top:not(.multinumber) {
      top: $borderMargin;
      left: $borderMargin;
    }

    &.bottom:not(.multinumber) {
      bottom: $borderMargin;
      right: $borderMargin;
      transform: rotate(180deg);
    }

    &.multinumber {
      &.top {
        top: $borderMargin;
        right: $borderMargin;
      }

      &.bottom {
        bottom: $borderMargin;
        left: $borderMargin;
        transform: rotate(180deg);
      }
    }
  }
}

.card-logo-back {
  width: 2.5em;
  height: 2.5em;
  transform: rotate(90deg);
}

$ribbonColor: white;

.ribbon {
  z-index: 0;
  font-size: 0.18em;
  position: relative;
  display: inline-block;
  font-family: $themeFont;
  font-weight: 500;
  letter-spacing: 0.11em;
  white-space: nowrap;
  text-align: center;
  margin: 1.5em 0;
  background-color: white;

  &:nth-child(3) {
    transform: rotate(180deg);
  }

  &:after,
  &:before {
    content: " ";
    display: block;
    position: absolute;
    bottom: -.5em;
    width: 1em;
    height: 100%;
    background-color: inherit;
    filter: brightness(90%);
    //background-color: darken($ribbonColor, 10);
    pointer-events: none;
  }

  &:after {
    right: -.57em;
    z-index: -1;
  }

  &:before {
    bottom: auto;
    top: -.5em;
    left: -.57em;
    z-index: -1;
  }

  .ribbon-inner {
    display: inline-block;
    position: relative;
    padding: .15em .75em;
    text-decoration: none;
    text-transform: lowercase;
    color: currentColor;
    background-color: inherit;

    &:after,
    &:before {
      content: " ";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      bottom: -.5em;
      left: -.05em;
      pointer-events: none;
      -webkit-mask: none;
    }

    &:before {
      bottom: auto;
      top: -.5em;
      border-bottom: .5em solid darken($ribbonColor, 30);
      border-left: .5em solid transparent;
    }

    &:after {
      left: auto;
      right: -.05em;
      border-top: .5em solid darken($ribbonColor, 30);
      border-right: .5em solid transparent;
    }
  }
}

.card-event-lightning {
  height: 1em;
  width: 1em;
  stroke: currentColor;
  fill: currentColor;
  stroke-width: 0.1em;
  transform: rotate(-15deg);
}