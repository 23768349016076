@import 'theme';

.point-limit-container {
  width: 98%;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bgLight;
  box-shadow: $dropShadow;
  margin-bottom: 0.6em;
  margin-right: 0.4em;
  padding: 0.2em 0.3em 0.2em 0.2em;
  border-radius: 0.3em;
  box-sizing: border-box;
  overflow: hidden;
  color: $accent;
  font-size: 1.5em;
  font-weight: 500;
}

.scoreboard-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.scoreboard-item-container {
  width: 98%;
  @include center;
  margin-bottom: 0.6em;
  margin-right: 0.6em;
}

.scoreboard-item {
  height: 2.5em;
  margin: 0;
  display: flex;

}

.rank {
  flex-shrink: 0;
  width: 2.4em;
  height: 2.4em;
  margin-right: 0.5em;
  border-radius: 50%;
  box-shadow: $dropShadow;
  background-color: $bgLight;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.username-text {
  margin-left: 1em;
  width: 48%;
  font-weight: 500;
}

.points-text {
  width: 19%;
  font-weight: 500;
  text-align: right;
}

.change-text-over-50 {
  width: 19%;
  white-space: nowrap;
  font-weight: 500;
  color: #ff3d3d;
  text-align: right;
}

.change-text-over-30 {
  width: 19%;
  white-space: nowrap;
  font-weight: 500;
  color: #ff6666;
  text-align: right;
}

.change-text-over-15 {
  width: 19%;
  white-space: nowrap;
  font-weight: 500;
  color: #ff9494;
  text-align: right;
}

.change-text-over-0 {
  width: 19%;
  white-space: nowrap;
  font-weight: 500;
  color: #ffbfbf;
  text-align: right;
}

.change-text-0 {
  width: 19%;
  white-space: nowrap;
  font-weight: 500;
  color: #fcc603;
  text-align: right;
}

.change-text-under-0 {
  width: 19%;
  white-space: nowrap;
  font-weight: 500;
  color: #85c771;
  text-align: right;
}

.podium-container {
  width: 100%;
  margin-right: 0.4em;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.podium-item {
  width: 100%;
  height: 13em;
  margin-left: 0.4em;
  margin-right: 0.4em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0.3em;
  overflow: hidden;
}

.avatar-container {
  display: flex;
  justify-content: flex-end;
  border-radius: 50%;
  box-shadow: $dropShadow;
}

.podium-text-container {
  width: 100%;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  box-shadow: $dropShadow;
  border-radius: 0.3em;
}

.rank-text {
  font-size: 1.7em;
  font-weight: 500;
}

.gold-margin {
  margin-bottom: 1.4em;
}

.silver-margin {
  margin-bottom: 0.5em;
}

.podium-text {
  margin-top: 0.2em;
  font-size: 0.8em;
  font-weight: 500;
}

.podium-text-bottom {
  margin-top: 0.2em;
  margin-bottom: 0.6em;
  font-size: 0.8em;
  font-weight: 500;
}

.gold {
  background-color: #dbb52a;
}

.gold-height {
  height: 9em;
}

.silver {
  background-color: #8e9899;
}

.silver-height {
  height: 7em;
}

.bronze {
  background-color: #8f612e;
}

.bronze-height {
  height: 6em;
}

@mixin captionWidth {
  &.c1 {
    width: 10%;
  }
  &.c2 {
    width: 62%;
  }
  &.c3 {
    width: 13%;
  }
  &.c4 {
    width: 15%;
  }
}

.player-list-caption-container {
  width: 100%;
  margin-bottom: 0.3em;
}

.player-list-caption {
  display: inline-block;
  font-family: $themeFont;
  font-size: 1rem;
  font-weight: 500;
  text-transform: lowercase;
  @include captionWidth;
}