@import 'theme';

.mating-season-animation {
  @include fillCenter;

  & .egg {
    width: 10em;
    height: 10em;
    border: 0.5em solid $accent;
    border-radius: 50%;
    box-shadow: 0 0 1em $accent;
    z-index: 1;

    &::before {
      display: block;
      content: '';
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: $accent;
      opacity: 0.1;
      box-shadow: inset 0 0 1em $accent;
    }
  }

  & .sperm-outer {
    position: absolute;

    & .sperm {
      width: 1em;
      height: 0.5em;
      border-radius: 50%;
      background-color: white;
      z-index: 0;
      animation-name: ms-sperm-swim;
      animation-duration: 5s;
      animation-fill-mode: forwards;
      opacity: 0;

      & .tail {
        position: absolute;
        left: -1em;
        width: 1.2em;
        height: 0.4em;
        margin-top: 0.05em;
        overflow: hidden;
        z-index: -1;

        & .tail-inner {
          position: absolute;
          width: 3em;
          animation: ms-sperm-tail 0.75s linear infinite;
          fill: white;
        }
      }
    }

    @for $i from 2 through 11 {
      &:nth-child(#{$i}) {
        transform: rotate(#{$i * 36}deg) translateX(-#{$i}em);

        & > .sperm {
          animation-delay: #{(11 - $i) * 0.2}s;
        }
      }
    }
  }
}

@keyframes ms-sperm-tail {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-67%);
  }
}

@keyframes ms-sperm-swim {
  0% {
    transform: translateX(-15em);
  }
  100% {
    opacity: 1;
    transform: translateX(-5em);
  }
}