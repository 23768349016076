@import 'theme';

.toolwindow-container {
  position: relative;
  color: $textColor;
  background-color: $bgMedium;
  border-radius: 0.6em;
  overflow: hidden;
  box-shadow: $dropShadow;
  padding: 0;
}

.toolwindow-header {
  font-size: $fontSizeSmallHeader;
  background-color: $accent;
  color: $bgMedium;
  height: 2em;
  margin: 0;
}

.toolwindow-title {
  margin: 0 0.8em;
  height: 100%;
  width: 100%;
  line-height: 2em;
  font-weight: 600;
  text-transform: lowercase;
  text-align: left;
}

.toolwindow-close {
  width: 0.75em;
  height: 0.75em;
  fill: $bgMedium;
  position: absolute;
  right: 0.75em;
  top: 0.64em;
  @include clickable;
}

.toolwindow-content {
  max-height: calc(100% - 2em);
  min-height: 4em;
  margin: 0;
  padding: 1.1em;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
  box-sizing: border-box;

  &.no-scroll {
    overflow-y: hidden;
  }
}