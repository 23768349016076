@import 'theme';

.end-container {
  max-height: 41.2em;
  @include center;
}

.end-column {
  &.timer {
    margin-right: 5%;
    width: 12em;
    align-self: flex-end;

    & .text {
      margin: 0 0 0.5em;
      font-size: 1.5em;
      font-weight: 500;
      color: $accent;
      text-transform: lowercase;
      text-shadow: $dropShadow;
    }
  }

  &.rest {
    align-self: stretch;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    width: 14.5em;
  }

  & .rest-spacer {
    margin: 0.5em;
  }
}

.end-timer {
  position: relative;
  width: 14.5em;
  margin-bottom: 1em;
  @include center;
  flex-direction: column;
  padding: 0.5em 0;

  &.text {
    margin: 0 1em 0 0;
    font-size: 1.5em;
    font-weight: 500;
    color: $accent;
    text-transform: lowercase;
    text-shadow: $dropShadow;
  }
}