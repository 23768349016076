@import 'theme';
@import 'ui/Window';

$inputHeight: 1.9em;

.window-container.chat {
  position: relative;
  width: 14.5em;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include scrollbar;
}

.chat-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.5em;
  @include scrollbar;
}

.chat-input-container {
  flex: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $bgLighter;
}


.chat-input {
  display: block;
  border: none;
  background-color: transparent;
  height: $inputHeight;
  width: 100%;
  color: $textColor;
  font-family: $themeFont;
  font-weight: 300;
  font-size: $fontSizeInput;
  padding-left: 0.5em;
}

.chat-send-button {
  fill: $accent;
  height: 1.4em;
  width: 1.4em;
  margin: 0 0.2em;
  flex-shrink: 0;

  @include clickable;
}

// Chat Items

$chatItemSize: $fontSizeChat;

.chat-item {
  width: 100%;
  padding: 0.25em 0.35em;
  display: flex;
  font-family: $themeFont;
  font-size: $chatItemSize;

  &.event {
    font-weight: 500;
    background-color: $bgLight;
  }
}

.chat-item-icon {
  display: inline;
  fill: currentColor;
  width: $chatItemSize;
  height: $chatItemSize;
  margin: 0 0.35em 0 0;
  transform: translateY(10%);
  flex-shrink: 0;
}

.chat-item-sender {
  display: inline;
  margin: 0 0.35em 0 0;
  font-weight: 500;
  line-height: $chatItemSize;
}

.chat-item-text {
  margin: 0;
  line-height: 1.25em;
  word-break: break-word;
}

.chat-username-highlight {
  color: $accent;
  font-weight: 500;
}