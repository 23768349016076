@import 'theme';

.concrete-picker-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.concrete-picker-spacer {
  height: 1.5em;
}