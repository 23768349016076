@import 'theme';

.lobby-list-container {
  width: 100%;
  margin-top: 1.4em;
}

@mixin captionWidth {
  &.c1 {
    width: 60%;
  }
  &.c2 {
    width: 30%;
  }
  &.c3 {
    width: 10%;
    text-align: right;
  }
}

.lobby-list-caption-container {
  padding: 0 1.2em;
}

.lobby-list-caption {
  display: inline-block;
  font-family: $themeFont;
  font-size: $fontSizeCaption;
  font-weight: 500;
  text-transform: lowercase;
  margin: 0.7em 0 0;
  @include captionWidth;
}

.lobby-list {
  list-style-type: none;
  padding: 0;
  min-height: 25vh;
}

.lobby-item {
  display: flex;
  align-items: center;
  background-color: $bgLight;
  border-radius: 0.3em;
  box-shadow: $dropShadow;
  padding: 0 1.2em;
  overflow: hidden;
  box-sizing: border-box;

  transition: filter 0.3s;

  &:hover {
    filter: brightness(110%);
  }
}

.lobby-item-text {
  font-size: $fontSizeDefault;
  font-weight: 500;
  @include captionWidth;
}