@import 'theme';
@import 'ui/cards/colors';

.earthquake-shaker {
  animation-name: earthquake-shake;
  animation-duration: 5s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
}

.earthquake-animation {
  position: relative;
  width: 100%;
  height: 20em;
  transform: rotate(-10deg);
  @include center;
  overflow: hidden;

  & .crack {
    position: relative;
    height: 100%;
    width: 50%;
    background-color: $accent;
    z-index: 0;
    $translate: 2.7em;
    animation-delay: 3s;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;

    &.left {
      transform: translateX(-$translate);
      animation-name: eq-crack-widen-left;
    }

    &.right {
      transform: translateX($translate);
      animation-name: eq-crack-widen-right;
    }
  }

  & .crack-border {
    position: absolute;
    fill: $accent;
    height: 100%;
    width: auto;
    z-index: 1;

    &.left {
      right: -4em;
    }

    &.right {
      left: -4em;
    }
  }

  & .crack-reveal {
    position: absolute;
    z-index: 5;
    height: 100%;
    width: 5em;
    background-color: $accent;
    animation: eq-crack-reveal 2s 1s forwards ease-in;
  }
}

@keyframes earthquake-shake {
  @for $i from 1 through 50 {
    #{$i * 2 - 1}% {
      transform: translateY(0);
    }
    #{$i * 2}% {
      transform: translateY(0.5em);
    }
  }
}

@keyframes eq-crack-reveal {
  100% {
    transform: translateY(100%);
  }
}

@keyframes eq-crack-widen-left {
  20% {
    transform: translateX(-10%);
  }
  40% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(-80%);
  }
}

@keyframes eq-crack-widen-right {
  20% {
    transform: translateX(10%);
  }
  40% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(80%);
  }
}