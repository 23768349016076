@import 'theme';

a {
  text-decoration: none;
  color: $textColor;
}

html {
  @include fullscreen;
  font-size: $rootFontSize;
  font-weight: 300;
  overflow-x: auto;
  overflow-y: hidden;
  @include scrollbar;
}

body {
  @include fullscreen;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bgDark;
}

code {
  display: block;
  white-space: pre-wrap;
}

#root {
  @include fullscreen;
}
