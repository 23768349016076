@import 'theme';
@import 'ui/cards/colors';

.mexican-standoff-container {
  position: relative;
  @include center;
  height: 10.2em;
}

.mexican-standoff-body {
  position: absolute;

  & .revolver {
    z-index: 1;
    position: absolute;
    fill: $accent;
    height: 10em;
    width: 15em;
    transform: rotate(90deg);
    animation-name: revolver-shoot;
    animation-duration: 5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }

  & .shot-hole {
    position: absolute;
    height: 3em;
    width: 3em;
    opacity: 0;
    fill: $bgLighter;
    transform: translateX(10em) translateY(-3em);
    animation-name: hole-appear;
    animation-duration: 0.001s;
    animation-delay: 1.8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &:nth-child(2) {
      transform: translateX(15em) translateY(-2.2em);
      animation-delay: 2.3s;
    }

    &:nth-child(3) {
      transform: translateX(13em) translateY(0.3em);
      animation-delay: 2.55s;
    }
  }

  & .muzzle-flash {
    position: absolute;
    transform: translateX(-5.5em) translateY(-7.5em);
    width: 13em;
    height: 13em;
    opacity: 0;
    animation-name: muzzle-flash-appear;
    animation-duration: 0.1s;
    animation-delay: 1.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  & .muzzle2 {
    animation-delay: 2.25s;
  }

  & .muzzle3 {
    animation-delay: 2.5s;
  }

  & .smoke {
    position: absolute;
    transform: translateX(-5em) translateY(-1em);
    width: 0.5em;
    height: 0.5em;
    opacity: 0;
    border-radius: 50%;
    filter: blur(0.7em);
    background-color: $white;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    $animationDelay: 0.32s;

    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) {
        animation-delay: $animationDelay * $i + 2s;
      }
    }

    &:nth-of-type(even) {
      animation-name: smoke-move-even;
    }

    &:nth-of-type(odd) {
      animation-name: smoke-move-odd;
    }
  }
}

@keyframes revolver-shoot {
  0% {
    opacity: 0;
    left: -25em;
    top: 0;
    transform: rotate(90deg);
  }
  10% {
    left: -25em;
    top: 0;
    transform: rotate(90deg);
  }
  30% {
    opacity: 100;
    left: -25em;
    top: 0;
    transform: rotate(90deg);
  }
  33% {
    left: -20em;
    top: -3em;
    transform: rotate(0deg);
  }
  38% {
    left: -20em;
    top: -3em;
    transform: rotate(0deg);
  }
  39% {
    left: -22em;
    top: -3.2em;
    transform: rotate(-10deg);
  }
  42% {
    left: -20em;
    top: -3em;
    transform: rotate(0deg);
  }
  47.5% {
    left: -20em;
    top: -3em;
    transform: rotate(0deg);
  }
  48.5% {
    left: -22em;
    top: -3.2em;
    transform: rotate(-10deg);
  }
  51.5% {
    left: -20em;
    top: -3em;
    transform: rotate(1deg);
  }
  52.5% {
    left: -20em;
    top: -3em;
    transform: rotate(0deg);
  }
  53.5% {
    left: -22em;
    top: -3.2em;
    transform: rotate(-10deg);
  }
  56.5% {
    left: -20em;
    top: -3em;
    transform: rotate(0deg);
  }
  100% {
    left: -20em;
    top: -3em;
    transform: rotate(0deg);
  }
}

@keyframes hole-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes muzzle-flash-appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

@keyframes smoke-move-even {
  0% {
    left: 0;
    top: 0;
    width: 0.5em;
    height: 0.5em;
    opacity: 10;
  }
  100% {
    width: 1em;
    height: 1em;
    left: 0.5em;
    top: -6.5em;
    opacity: 0;
  }
}

@keyframes smoke-move-odd {
  0% {
    left: 0;
    top: 0;
    width: 0.5em;
    height: 0.5em;
    opacity: 10;
  }
  100% {
    width: 1em;
    height: 1em;
    left: -0.5em;
    top: -6.5em;
    opacity: 0;
  }
}