@import 'theme';

// Draw Stack

.card-stack {
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 2.8 * 3.1em + 0.5em;
  transition: 0.3s;
  user-select: none;

  &.draw.active {
    cursor: pointer;

    &:hover {
      filter: brightness(110%);
    }
  }
}

.card-stack-border {
  z-index: 1;
  width: 2.8 * 3.1em + 0.5em;
  height: 100%;
  position: absolute;
  top: -0.1em;
  left: -0.1em;
  user-select: none;
  border-radius: 0.8em;
  transition: 0.3s;
  border: none;
  box-shadow: none;

  &.active {
    border: 0.1em solid $availableCardColor;
    box-shadow: 0 0 0.5em 0 $availableCardColor;
  }
}

.card-stack-card {
  width: 0.15em;
  // see BaseStackTransition for
  // DiscardPile BaseStack width
}

.draw-stack-animation {
  position: absolute;
  animation: draw-card 0.5s 1;
  opacity: 0;
}

@keyframes draw-card {
  0% {
    opacity: 1;
    transform: translateX(0%) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translateX(-200%) rotate(180deg);
  }
}

// Discard Pile

.discard-pile-top-card {
  position: absolute;
}

.card-stack-dummy {
  height: 4.4 * 2.8em;
}

@keyframes play-card {
  0% {
    opacity: 0;
    transform: translateX(200%) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0%) rotate(0deg);
  }
}