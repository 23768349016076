@import 'theme';

.end-text-container {
  display: flex;
}

.end-p {
  margin: 0 0 0.5em;
}

.end-icon {
  fill: $accent;
  height: 2em;
  width: 2em;
  margin-right: 1em;
}

.end-scoreboard-container {
  height: 23em;
  overflow: auto;
  @include scrollbar;
}

.end-actions-text {
  font-size: 1.5em;
  font-weight: 500;
  color: $accent;
  text-transform: lowercase;
  margin: 0.4em 0.5em 0 0;
}

.end-actions-container {
  margin-top: 1.5em;
  @include center;

  & > .turn-timer-container > .turn-timer-inner {
    text-shadow: none;
    width: 6em;
  }

  & > .turn-timer-container > .turn-timer-inner > .turn-timer {
    filter: none;
  }
}