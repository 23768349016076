@import 'theme';

.mainmenu-container {
  width: 100%;
  height: 100%;
  @include center;
  flex-direction: column;
  overflow: hidden;
}

.mainmenu-logo {
  width: 16em;
  height: 16em;
  fill: $accent;
  animation: mainmenu-logo-spin infinite 20s linear;
}

.mainmenu-logo-shadow-container {
  filter: drop-shadow($dropShadow);
}

.mainmenu-items-container {
  height: 16em;
  @include center;
  flex-direction: column;
}

.mainmenu-item {
  margin: 0;
  color: $accent;
  font-size: $fontSizeDefault * 2;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  text-transform: lowercase;
  text-shadow: $dropShadow;
  @include clickable;

  &.secondary {
    font-size: $fontSizeDefault * 1.5;
    color: $textColor;
    opacity: 70%;
  }
}

.madewith {
  font-family: $themeFont;
  color: $accent;
  margin: 0;
  overflow: visible;
  text-align: center;

  &.label {
    position: absolute;
    bottom: 0.5em;
  }
  &.link {
    display: block;
    position: relative;
    @include clickable;
  }
}

.copyright-disclaimer {
  display: block;
  position: relative;
  color: $textColor;
  line-height: 1em;
  margin-top: 1em;

  &.link {
    display: inline-block;
    color: $accent;
    margin: 0;
    @include clickable;
  }
}

.mainmenu-workswith {
  display: block;
  font-size: $fontSizeSmallHeader;
  color: $textColor;
  text-transform: lowercase;
  opacity: 0.5;
}

@keyframes mainmenu-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
