@import 'theme';

.app-container {
  position: absolute;
  @include fullscreen;
  @include center;
  overflow: hidden;
  transition: 0.3s;

  &.blur {
    filter: blur(0.3em);
  }
}

.app-container.animated {
  pointer-events: none;
}

.ac-top-right-buttons {
  position: absolute;
  top: 0.5em;
  right: 1.5em;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  & > .mainmenu-item:not(:first-child) {
    margin-right: 0.5em;
  }
}

.back-button {
  position: absolute;
  top: 0.5em;
  left: 0.8em;
}

.help-button {
  position: absolute;
  top: 0.5em;
  right: 1.5em;
}

.top-icon {
  fill: $accent;
  height: $fontSizeDefault * 1.25;
  width: $fontSizeDefault * 1.25;
  margin-bottom: 0.06em;
  filter: drop-shadow($dropShadow);
}
