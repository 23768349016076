@import 'theme';

.lobbies-container {
  width: 100%;
  height: calc(100% - 4.2em);
  margin-top: 2em;
  padding-right: 0.5em;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
}

.lobbies-centerer {
  @include fillCenter;
}

.no-lobbies {
  &.container {
    @include fillCenter;
  }

  &.msg {
    text-align: center;
    font-size: $fontSizeCaption;
    color: $accent;
  }
}