@import 'theme';
@import 'ui/cards/colors';

.third-time-lucky-animation {
  position: relative;
  @include center;
  flex-direction: row;

  & .board-first-circle {
    position: relative;
    @include center;
    border: 0.2em solid $accent;
    width: 3.5em;
    height: 11em;
    border-radius: 50%;
    right: -9em;
    bottom: 1.5em;
    animation: bullseye-circle 1s forwards;
    animation-delay: 3.6s;
    animation-iteration-count: 2;


    & .board-second-circle {
      position: relative;
      @include center;
      border: 0.2em solid $accent;
      width: 1.7em;
      height: 7em;
      left: -0.15em;
      border-radius: 50%;
      animation: bullseye-circle 1s forwards;
      animation-delay: 3.4s;
      animation-iteration-count: 2;

      & .board-center {
        position: relative;
        @include center;
        background: $accent;
        width: 0.6em;
        height: 2.7em;
        left: -0.13em;
        border-radius: 50%;
        animation: bullseye 1s forwards;
        animation-delay: 3.3s;
        animation-iteration-count: 2;


        &::before {
          content: '';
          display: block;
          @include center;
          background: $black;
          width: 0.25em;
          height: 1.3em;
          border-radius: 50%;
        }
      }
    }
  }

  & .dart {
    $dartWidth: 0.15em;
    position: absolute;
    color: $accent;
    animation-name: dart-throw;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    z-index: 1;
    transform-origin: 50% 50em;
    opacity: 0;

    &:nth-child(1) {
      top: -2em;
      right: 3.5em;
      animation-delay: 0.5s;
    }

    &:nth-child(2) {
      top: 7.5em;
      right: 1.5em;
      animation-delay: 1.5s;
    }

    &:nth-child(3) {
      top: 3.3em;
      right: 2em;
      animation-delay: 2.5s;
    }

    & .dart-body {
      position: relative;
      background: currentColor;
      height: $dartWidth;
      width: 1.8em;

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 0.5em;
        height: 0.2em;
        top: 0.1em;
        left: -0.1em;
        transform: skew(-40deg);
        background: currentColor;
      }

      &::after {
        position: absolute;
        content: '';
        display: block;
        background: currentColor;
        width: 0.5em;
        height: 0.2em;
        transform: skew(40deg);
        top: -0.15em;
        left: -0.1em;
      }
    }

    & .dart-head {
      position: absolute;
      width: 0;
      height: 0;
      border-top: ($dartWidth / 2) solid transparent;
      border-left: $dartWidth solid currentColor;
      border-bottom: ($dartWidth / 2) solid transparent;
      right: -$dartWidth;
      top: 0;
    }
  }
}

@keyframes dart-throw {
  0% {
    opacity: 0;
    transform: rotate(-20deg);
  }
  20% {
    opacity: 1;
    transform: rotate(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotate(10deg);
  }
}

@keyframes bullseye {
  50% {
    background-color: $white;
  }
  100% {
    background-color: $accent;
  }
}

@keyframes bullseye-circle {
  50% {
    border-color: $white;
  }
  100% {
    border-color: $accent;
  }
}

